body {
  font-size: 16px;
  font-family: Roboto, Arial, sans-serif;
  color: rgb(50, 50, 50);
  overflow-x: hidden;
}

*,
::before,
::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: rgb(57, 169, 73);
}

/* Place cookie banner outside viewport for better LCP performance */

@keyframes slide-up {
  0% {
    transform: translateY(110vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

#onetrust-banner-sdk {
  transform: translateY(110vh);
  animation: slide-up 0.5s forwards;
}
